
/* This fires as soon as the element enters the DOM*/
.list-transition-enter {
/*We give the list the initial dimension of the list button*/
top: 0;
width: 120px;
max-height: 40px;
color: transparent;
background-color: #5a564c;
}
/* This is where we can add the transition*/
.list-transition-enter-active {
top: 45px;
width: 200px;
max-height: 200px;
background-color: #9e8949;
transition: all 400ms;
}
/* This fires as soon as the this.state.showList is false */
.list-transition-exit {
top: 45px;
width: 200px;
max-height: 200px;
background-color: #9e8949;
}
/* fires as element leaves the DOM*/
.list-transition-exit-active {
top: 0;
width: 120px;
max-height: 40px;
color: transparent;
background-color: #5a564c;
transition: all 400ms;
}

.fade-appear {
  opacity: 0;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 1000ms linear;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 1000ms linear;
}

.fade-exit-done {
  opacity: 0;
}

/* slide */
.slide-enter {
  opacity: 0;
  transform: scale(0.97) translateY(5px);
}

.slide-enter.slide-enter-active {
  opacity: 1;
  transform: scale(1) translateY(0);
  transition: opacity 1000ms linear 100ms, transform 300ms ease-in-out 100ms;
}

.slide-exit {
  opacity: 1;
  transform: scale(1) translateY(0);
}

.slide-exit.slide-exit-active {
  opacity: 0;
  transform: scale(0.97) translateY(5px);
  transition: opacity 150ms linear, tranform 150ms ease-out;
}

..slide-exit-done {
  opacity: 0;
}
