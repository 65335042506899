/**
 * Software distributed under the Apache License is distributed on an "AS IS" basis,
 * WITHOUT WARRANTY OF ANY KIND, either express or implied. See the License for the
 * specific language governing rights and limitations under the License.
 */

:root {
  --crossmark-border-color: #C96363;
  --crossmark-fill-color: #fff;
  --crossmark-arrow-color: #C96363;
  --crossmark-arrow-thickness: 4;
  --crossmark-circle-thickness: 1;
}

.crossmark {

  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  stroke: var(--crossmark-arrow-color);
  stroke-width: var(--crossmark-arrow-thickness);
  stroke-miterlimit: 10;
  //box-shadow: inset 0 0 0 var(--crossmark-fill-color);
  animation: border-width 0.4s ease-in-out 0.4s forwards, fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}

.crossmark__circle {

  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: var(--crossmark-circle-thickness);
  stroke-miterlimit: 10;
  stroke: var(--crossmark-border-color);
  fill: none;
  animation: stroke 0.9s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.crossmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.0s cubic-bezier(0.65, 0, 0.45, 1) 0.9s forwards, fill 0.4s ease-in-out 0.9s forwards, scale 0.3s ease-in-out 0.9s both;
}
.crossmark__check2 {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.0s cubic-bezier(0.65, 0, 0.45, 1) 0.9s forwards, fill 0.4s ease-in-out 0.9s forwards, scale 0.3s ease-in-out 0.9s both;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0 0 0 100vh var(--crossmark-fill-color);
  }
}
